import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const ProjectsVerticalMenu = (props) => {
	const projects = props.projects;
	let projectCounter = 0;

	useEffect(() => {
		projectCounter = 0;
	}, []);

	const pathname = useLocation();

	return (
		<React.Fragment>
			<div className="widget-area col-lg-3 col-md-12">
				<div className="widget widget_nav_menu">
					<ul className="services-menu">
						{projects.map((project) => {
							projectCounter++;

							return (
								<li
									key={"item-" + projectCounter.toString()}
									className={
										pathname.pathname.indexOf(project.route) > -1
											? "current-menu-item"
											: ""
									}>
									<Link to={project.route}>
										<span>0{projectCounter}. </span> {project.linkName}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ProjectsVerticalMenu;
