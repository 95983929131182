import { Link } from "react-router-dom";

import Header from "components/Header";
import Counters from "components/Counters";
import Footer from "components/Footer";

import bgImg from "images/about-min.jpg";
import bgAboutImg from "images/bg1-aboutus.jpg";

import pillar1Img from "images/support2.jpg";
import pillar2Img from "images/support3.jpg";

import aboutImg from "images/image1-home3.png";

import testimonials from "testimonial.js";

import { about } from "site-structure.js";

const About = (props) => {
	const renderTestimonial = () =>
		testimonials.map((testimonial) => (
			<div
				key={testimonial.key}
				className="owl-item cloned"
				sx="width: 370px; margin-right: 30px;">
				<div className="testi-item">
					<div className="ttext">
						<div className="layer-behind"></div>
						<span></span>
						{testimonial.text}
					</div>
					<div className="t-head flex-middle">
						<img src="images/testi1.png" alt="" />
						<div className="tinfo">
							<h6>{testimonial.name}</h6>
						</div>
					</div>
				</div>
			</div>
		));

	return (
		<>
			<div id="page" className="site">
				<Header transparent={true} title={props.title} />

				<div id="content" className="site-content">
					<div
						className="page-header dtable text-center header-transparent pheader-about"
						style={{
							backgroundImage: `linear-gradient(0deg, rgba(33, 33, 33, .42) 0%, rgba(33, 33, 33, .42) 100%), url(${bgImg})`,
						}}>
						<div className="dcell">
							<div className="container">
								<h1 className="page-title">{about.pageTitle}</h1>
								<ul id="breadcrumbs" className="breadcrumbs none-style">
									<li>
										<a href="index.html">Home</a>
									</li>
									<li className="active">{about.pageTitle}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<section className="about-company">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-12 align-self-center text-center mb-5 mb-lg-0">
								<div className="about-img">
									<img src={aboutImg} alt="" />
								</div>
							</div>
							<div className="col-lg-6 col-md-12 align-self-center">
								<div className="about-detail">
									<div className="ot-heading is-dots">
										<span>[ {about.sectionSubtitle} ]</span>
										<h2 className="main-heading">{about.sectionTitle}</h2>
									</div>
									<p>{about.sectionDescription}</p>
									<div className="ot-button">
										<Link
											to="/contatti"
											className="octf-btn octf-btn-dark border-hover-dark">
											Contattaci
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					className="our-philosophy"
					style={{ backgroundImage: `url(${bgAboutImg})` }}>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 theratio-align-center text-center">
								<div className="ot-heading is-dots">
									<span>[ pilastri ]</span>
									<h2 className="main-heading text-light">Le nostre basi</h2>
								</div>
							</div>
						</div>
						<div className="row justify-content-center">
							<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-5 mb-xl-0">
								<div className="support-box">
									<div className="inner-box">
										<div className="overlay flex-middle">
											<div className="inner">
												<p>{about.sectionPillars[0].description}</p>
											</div>
										</div>
										<div className="content-box">
											<div className="icon-title">
												<span className="ot-flaticon-brickwall"></span>
												<h5>{about.sectionPillars[0].title}</h5>
											</div>
											<img src={pillar1Img} alt="" />
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-5 mb-xl-0">
								<div className="support-box">
									<div className="inner-box">
										<div className="overlay flex-middle">
											<div className="inner">
												<p>{about.sectionPillars[1].description}</p>
											</div>
										</div>
										<div className="content-box">
											<div className="icon-title">
												<span className="ot-flaticon-paint"></span>
												<h5>{about.sectionPillars[1].title}</h5>
											</div>
											<img src={pillar2Img} className="" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="about-testi">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center theratio-align-center">
								<div className="ot-heading is-dots">
									<span>[ Testimonianze ]</span>
									<h2 className="main-heading">Dicono di noi</h2>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="ot-testimonials v-light">
									<div className="testimonial-inner testi-col-3 owl-theme owl-carousel owl-loaded owl-drag">
										<div className="owl-stage-outer">
											<div
												className="owl-stage"
												sx="transform: translate3d(-1200px, 0px, 0px); transition: all 0s ease 0s; width: 4800px;">
												{renderTestimonial()}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		</>
	);
};

export default About;
