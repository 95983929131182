import React from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import ServicesVerticalMenu from "components/ServicesVerticalMenu";
import Footer from "components/Footer";

import bgImg from "images/page-header/pheader-service-detail1.jpg";

import testimonials from "testimonial.js";

const Servizio = (props) => {
	const services = props.services;
	const service = props.service;

	const renderTestimonial = () =>
		testimonials.map((testimonial) => (
			<div
				key={testimonial.name.replace(" ", "")}
				className="owl-item cloned"
				style={{ marginBottom: "56px" }}>
				<div className="testi-item">
					<div className="ttext">
						<div className="layer-behind"></div>
						<span></span>
						{testimonial.text}
					</div>
					<div className="t-head flex-middle">
						<div className="tinfo">
							<h6>{testimonial.name}</h6>
						</div>
					</div>
				</div>
			</div>
		));

	return (
		<React.Fragment>
			<div id="page" className="site">
				<Header transparent={true} title={props.title} />

				<div id="content" className="site-content">
					<div
						className="page-header dtable text-center header-transparent pheader-service-detail1"
						style={{
							backgroundImage: `linear-gradient(0deg, rgba(33, 33, 33, .42) 0%, rgba(33, 33, 33, .42) 100%), url(${service.img})`,
						}}>
						<div className="dcell">
							<div className="container">
								<h1 className="page-title">{service.pageTitle}</h1>
								<ul id="breadcrumbs" className="breadcrumbs none-style">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>{service.section}</li>
									<li className="active">{service.linkName}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<section className="services-single">
					<div className="container">
						<div className="row">
							<ServicesVerticalMenu services={services} />

							<div className="col-lg-9 col-md-12">
								<div className="services-detail-content">
									<div className="ot-heading ">
										<span>[ La nostra offerta - {service.pageTitle} ]</span>
										<h2 className="main-heading">{service.description}</h2>
									</div>

									<div dangerouslySetInnerHTML={{ __html: service.text }}></div>

									<img src={service.img} className="detail-img" alt="" />

									<div className="ot-heading is-dots service-testi">
										<span>[ Testimonianze ]</span>
										<h2 className="main-heading">Dicono di noi</h2>
									</div>
									<div className="ot-testimonials v-light">
										<div className="testimonial-inner ot-testimonials-slider-s1 owl-theme owl-carousel owl-loaded owl-drag">
											<div className="owl-stage-outer">
												<div
													className="owl-stage"
													sx="transform: translate3d(-900px, 0px, 0px); transition: all 0s ease 0s; width: 3150px;">
													{renderTestimonial()}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Servizio;
