// Pages
import Home from "pages/Home";
import About from "pages/About";
import Servizio from "pages/Servizio";
import Progetto from "pages/Progetto";
import Progetti from "pages/Progetti";
import Contatti from "pages/Contatti";

import {
	home,
	about,
	services,
	projects,
	projectsPage,
	contacts,
} from "site-structure.js";

const appname = process.env.REACT_APP_WEBSITE_NAME;

const routes = [
	{
		key: "home",
		...home,
		component: <Home title={`${home.pageTitle} - ${appname}`} />,
	},
	{
		key: "about",
		...about,
		component: <About title={`${about.pageTitle} - ${appname}`} />,
	},
	{
		key: "services",
		section: "Servizi",
		linkName: "Servizi",
		pageTitle: "Servizi",
		route: "/servizi",
		collapse: [
			{
				key: "costruzioni",
				...services[0],
				component: (
					<Servizio
						title={`${services[0].pageTitle} - ${appname}`}
						services={services}
						service={services[0]}
					/>
				),
			},
			{
				key: "ristrutturazioni",
				...services[1],
				component: (
					<Servizio
						title={`${services[1].pageTitle} - ${appname}`}
						services={services}
						service={services[1]}
					/>
				),
			},
			{
				key: "coperture",
				...services[2],
				component: (
					<Servizio
						title={`${services[2].pageTitle} - ${appname}`}
						services={services}
						service={services[2]}
					/>
				),
			},
			{
				key: "manutenzioni",
				...services[3],
				component: (
					<Servizio
						title={`${services[3].pageTitle} - ${appname}`}
						services={services}
						service={services[3]}
					/>
				),
			},
			{
				key: "movimento-terra",
				...services[4],
				component: (
					<Servizio
						title={`${services[4].pageTitle} - ${appname}`}
						services={services}
						service={services[4]}
					/>
				),
			},
			{
				key: "isolamenti-termici",
				...services[5],
				component: (
					<Servizio
						title={`${services[5].pageTitle} - ${appname}`}
						services={services}
						service={services[5]}
					/>
				),
			},
			{
				key: "rifacimento-bagno",
				...services[6],
				component: (
					<Servizio
						title={`${services[6].pageTitle} - ${appname}`}
						services={services}
						service={services[6]}
					/>
				),
			},
		],
		collapseType: "standard",
	},
	{
		key: "projects",
		...projectsPage,
		route: "/realizzazioni",
		component: (
			<Progetti
				title={`${projectsPage.pageTitle} - ${appname}`}
				projects={projects}
			/>
		),
		// collapse: [
		// 	{
		// 		key: "costruzione",
		// 		section: "Costruzione",
		// 		collapse: [
		// 			{
		// 				key: "progetto1",
		// 				...projects[0],
		// 				component: (
		// 					<Progetto
		// 						key="progetto1"
		// 						title={`${projects[0].pageTitle} - ${appname}`}
		// 						projects={projects}
		// 						project={projects[0]}
		// 					/>
		// 				),
		// 			},
		// 			{
		// 				key: "progetto2",
		// 				...projects[1],
		// 				component: (
		// 					<Progetto
		// 						key="progetto2"
		// 						title={`${projects[1].pageTitle} - ${appname}`}
		// 						projects={projects}
		// 						project={projects[1]}
		// 					/>
		// 				),
		// 			},
		// 		],
		// 	},
		// 	{
		// 		key: "ristrutturazione",
		// 		section: "Ristrutturazione",
		// 		collapse: [
		// 			{
		// 				key: "progetto3",
		// 				...projects[2],
		// 				component: (
		// 					<Progetto
		// 						key="progetto3"
		// 						title={`${projects[2].pageTitle} - ${appname}`}
		// 						projects={projects}
		// 						project={projects[2]}
		// 					/>
		// 				),
		// 			},
		// 			{
		// 				key: "progetto4",
		// 				...projects[3],
		// 				component: (
		// 					<Progetto
		// 						key="progetto4"
		// 						title={`${projects[3].pageTitle} - ${appname}`}
		// 						projects={projects}
		// 						project={projects[3]}
		// 					/>
		// 				),
		// 			},
		// 		],
		// 	},
		// ],
	},
	{
		key: "contacts",
		...contacts,
		component: (
			<Contatti title={`${contacts.pageTitle} - ${appname}`} page={contacts} />
		),
	},
];

export { routes };
