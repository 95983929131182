import React from "react";

import { Link } from "react-router-dom";

import { hero, projects, contacts } from "site-structure.js";

const Footer = () => {
	return (
		<React.Fragment>
			<footer id="site-footer" className="site-footer">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 mb-xl-0">
							<div className="widget-footer">
								<img
									src="images/logo-footer.svg"
									className="footer-logo"
									alt=""
								/>
								<p>
									<strong>{hero.title}.</strong>
									<br />
									{hero.subtitle}
								</p>
								<div className="footer-social list-social">
									<ul>
										<li>
											<Link to={contacts.facebook} target="_blank">
												<i className="fab fa-facebook-f"></i>
											</Link>
										</li>
										<li>
											<Link to={contacts.instagram} target="_blank">
												<i className="fab fa-instagram"></i>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 mb-xl-0">
							<div className="widget-footer">
								<h6>Contatti</h6>
								<ul className="footer-list">
									<li className="footer-list-item">
										<span className="list-item-icon">
											<i className="ot-flaticon-place"></i>
										</span>
										<span className="list-item-text">
											Via Armando Diaz, 26845, Codogno (LO)
										</span>
									</li>
									<li className="footer-list-item">
										<span className="list-item-icon">
											<i className="ot-flaticon-mail"></i>
										</span>
										<span className="list-item-text">
											info@gagliardicostruzioni.it
										</span>
									</li>
									<li className="footer-list-item">
										<span className="list-item-icon">
											<i className="ot-flaticon-phone-call"></i>
										</span>
										<span className="list-item-text">338 784 5683</span>
									</li>
									<li className="footer-list-item">
										<span className="list-item-icon">
											<i className="ot-flaticon-phone-call"></i>
										</span>
										<span className="list-item-text">366 712 8001</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
							<p>
								Copyright © {new Date().getFullYear()}{" "}
								<a className="text-light" href="/">
									Gagliardi Costruzioni di Gagliardi Marco
								</a>
								. All Rights Reserved.
							</p>
						</div>
						<div className="col-lg-5 col-md-12 align-self-center">
							<ul className="icon-list-items inline-items justify-content-lg-end">
								<li className="icon-list-item inline-item">
									<a href="/">
										<span className="icon-list-text">Termini di utilizzo</span>
									</a>
								</li>
								<li className="icon-list-item inline-item">
									<a href="/">
										<span className="icon-list-text">Privacy Policy</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Footer;
