import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const ServicesVerticalMenu = (props) => {
	const services = props.services;
	let serviceCounter = 0;

	useEffect(() => {
		serviceCounter = 0;
	}, []);

	const pathname = useLocation();

	return (
		<React.Fragment>
			<div className="widget-area col-lg-3 col-md-12">
				<div className="widget widget_nav_menu">
					<ul className="services-menu">
						{services.map((service) => {
							serviceCounter++;

							return (
								<li
									key={"item-" + serviceCounter.toString()}
									className={
										pathname.pathname.indexOf(service.route) > -1
											? "current-menu-item"
											: ""
									}>
									<Link to={service.route}>
										<span>0{serviceCounter}. </span> {service.linkName}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ServicesVerticalMenu;
