import React, { useState } from "react";
import { Link } from "react-router-dom";

import costruzioniImg from "images/iconbox1.2.png";
import ristrutturazioniImg from "images/iconbox3.png";
import copertureImg from "images/iconbox2.png";
import manutenzioniImg from "images/iconbox1.2.png";
import movimentoTerraImg from "images/iconbox3.png";
import isolamentiTermiciImg from "images/iconbox2.png";
import rifacimentoBagnoImg from "images/iconbox2.png";

import costruzioniBgImg from "images/bg/bg-iconbox1.jpg";
import ristrutturazioniBgImg from "images/bg/bg-iconbox3.jpg";
import copertureBgImg from "images/bg/bg-iconbox2.jpg";
import manutenzioniBgImg from "images/bg/bg-iconbox1.jpg";
import movimentoTerraBgImg from "images/bg/bg-iconbox3.jpg";
import isolamentiTermiciBgImg from "images/bg/bg-iconbox2.jpg";
import rifacimentoBagnoBgImg from "images/bg/bg-iconbox2.jpg";

import costruzioniBgHoverImg from "images/bg/bg-iconbox1-hover.jpg";
import ristrutturazioniBgHoverImg from "images/bg/bg-iconbox3-hover.jpg";
import copertureBgHoverImg from "images/bg/bg-iconbox2-hover.jpg";
import manutenzioniBgHoverImg from "images/bg/bg-iconbox1-hover.jpg";
import movimentoTerraBgHoverImg from "images/bg/bg-iconbox3-hover.jpg";
import isolamentiTermiciBgHoverImg from "images/bg/bg-iconbox2-hover.jpg";
import rifacimentoBagnoBgHoverImg from "images/bg/bg-iconbox2-hover.jpg";

import { services } from "site-structure.js";

const Services = () => {
	const costruzioni = services[0];
	const ristrutturazioni = services[1];
	const coperture = services[2];
	const manutenzioni = services[3];
	const movimentoTerra = services[4];
	const isolamentiTermici = services[5];
	const rifacimentoBagno = services[6];

	const [isHover, setIsHover] = useState([
		{
			costruzioni: false,
			ristrutturazioni: false,
			coperture: false,
			manutenzioni: false,
			movimentoTerra: false,
			isolamentiTermici: false,
			rifacimentoBagno: false,
		},
	]);

	const costruzioniStyle = {
		backgroundImage: isHover.costruzioni
			? `url(${costruzioniBgHoverImg})`
			: `url(${costruzioniBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	const ristrutturazioniStyle = {
		backgroundImage: isHover.ristrutturazioni
			? `url(${ristrutturazioniBgHoverImg})`
			: `url(${ristrutturazioniBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	const copertureStyle = {
		backgroundImage: isHover.coperture
			? `url(${copertureBgHoverImg})`
			: `url(${copertureBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	const manutenzioniStyle = {
		backgroundImage: isHover.manutenzioni
			? `url(${manutenzioniBgHoverImg})`
			: `url(${manutenzioniBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	const movimentoTerraStyle = {
		backgroundImage: isHover.movimentoTerra
			? `url(${movimentoTerraBgHoverImg})`
			: `url(${movimentoTerraBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	const isolamentiTermiciStyle = {
		backgroundImage: isHover.isolamentiTermici
			? `url(${isolamentiTermiciBgHoverImg})`
			: `url(${isolamentiTermiciBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	const rifacimentoBagnoStyle = {
		backgroundImage: isHover.rifacimentoBagno
			? `url(${rifacimentoBagnoBgHoverImg})`
			: `url(${rifacimentoBagnoBgImg})`,
		backgroundRepeat: "no-repeat",
	};

	return (
		<React.Fragment>
			<section className="services-3">
				<div className="grid-lines grid-lines-vertical">
					<span className="g-line-vertical line-left color-line-default"></span>
					<span className="g-line-vertical line-right color-line-default"></span>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center theratio-align-center">
							<div className="ot-heading is-dots">
								<span>[ SERVIZI ]</span>
								<h2 className="main-heading">La nostra offerta</h2>
							</div>
							<div className="space-50"></div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-1 text-center mb-30"
								style={costruzioniStyle}
								onMouseEnter={() => setIsHover({ costruzioni: true })}
								onMouseLeave={() => setIsHover({ costruzioni: false })}>
								<div className="icon-main">
									<img src={costruzioniImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={costruzioni.route}>{costruzioni.pageTitle}</Link>
									</h5>
									<p>{costruzioni.description}</p>
								</div>
								<div className="link-box">
									<Link to={costruzioni.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-3 text-center mb-30"
								style={ristrutturazioniStyle}
								onMouseEnter={() => setIsHover({ ristrutturazioni: true })}
								onMouseLeave={() => setIsHover({ ristrutturazioni: false })}>
								<div className="icon-main">
									<img src={ristrutturazioniImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={ristrutturazioni.route}>
											{ristrutturazioni.pageTitle}
										</Link>
									</h5>
									<p>{ristrutturazioni.description}</p>
								</div>
								<div className="link-box">
									<Link to={ristrutturazioni.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-2 text-center mb-30"
								style={copertureStyle}
								onMouseEnter={() => setIsHover({ coperture: true })}
								onMouseLeave={() => setIsHover({ coperture: false })}>
								<div className="icon-main">
									<img src={copertureImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={coperture.route}>{coperture.pageTitle}</Link>
									</h5>
									<p>{coperture.description}</p>
								</div>
								<div className="link-box">
									<Link to={coperture.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-2 text-center mb-30"
								style={manutenzioniStyle}
								onMouseEnter={() => setIsHover({ manutenzioni: true })}
								onMouseLeave={() => setIsHover({ manutenzioni: false })}>
								<div className="icon-main">
									<img src={manutenzioniImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={manutenzioni.route}>
											{manutenzioni.pageTitle}
										</Link>
									</h5>
									<p>{manutenzioni.description}</p>
								</div>
								<div className="link-box">
									<Link to={manutenzioni.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-2 text-center mb-30"
								style={movimentoTerraStyle}
								onMouseEnter={() => setIsHover({ movimentoTerra: true })}
								onMouseLeave={() => setIsHover({ movimentoTerra: false })}>
								<div className="icon-main">
									<img src={movimentoTerraImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={movimentoTerra.route}>
											{movimentoTerra.pageTitle}
										</Link>
									</h5>
									<p>{movimentoTerra.description}</p>
								</div>
								<div className="link-box">
									<Link to={movimentoTerra.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-2 text-center mb-30"
								style={isolamentiTermiciStyle}
								onMouseEnter={() => setIsHover({ isolamentiTermici: true })}
								onMouseLeave={() => setIsHover({ isolamentiTermici: false })}>
								<div className="icon-main">
									<img src={isolamentiTermiciImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={isolamentiTermici.route}>
											{isolamentiTermici.pageTitle}
										</Link>
									</h5>
									<p>{isolamentiTermici.description}</p>
								</div>
								<div className="link-box">
									<Link to={isolamentiTermici.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div
								className="icon-box icon-box--bg-img icon-box--icon-top icon-box--is-line-hover icon-bg-2 text-center mb-30"
								style={rifacimentoBagnoStyle}
								onMouseEnter={() => setIsHover({ rifacimentoBagno: true })}
								onMouseLeave={() => setIsHover({ rifacimentoBagno: false })}>
								<div className="icon-main">
									<img src={rifacimentoBagnoImg} alt="" />
								</div>
								<div className="content-box">
									<h5>
										<Link to={rifacimentoBagno.route}>
											{rifacimentoBagno.pageTitle}
										</Link>
									</h5>
									<p>{rifacimentoBagno.description}</p>
								</div>
								<div className="link-box">
									<Link to={rifacimentoBagno.route} className="btn-details">
										SCOPRI DI PIU'
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Services;
