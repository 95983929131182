import { counters } from "site-structure.js";

const Counters = () => {
	return (
		<>
			<div className="row">
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mb-xl-0">
					<div className="ot-counter">
						<div>
							<span>[</span>
							<span className="num" data-to="30" data-time="2000">
								{counters[0].value}
							</span>
							<span>+]</span>
						</div>
						<h6>{counters[0].name}</h6>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mb-xl-0">
					<div className="ot-counter">
						<div>
							<span>[</span>
							<span className="num" data-to="5" data-time="2000">
								{counters[1].value}
							</span>
							<span>+]</span>
						</div>
						<h6>{counters[1].name}</h6>
					</div>
				</div>
			</div>
			<div className="space-80"></div>
		</>
	);
};

export default Counters;
