import React from "react";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { projects } from "site-structure.js";

const Projects = () => {
	return (
		<React.Fragment>
			<section className="our-skill-3 pb-0">
				<div className="grid-lines grid-lines-vertical">
					<span className="g-line-vertical line-left color-line-default"></span>
					<span className="g-line-vertical line-right color-line-default"></span>
				</div>
				{/* <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="our-skill-content-3 mr-xl-70">
                            <div className="ot-heading is-dots">
                                <span>[ our skills ]</span>
                                <h2 className="main-heading">The Core Company Values</h2>
                            </div>
                            <div className="space-20"></div>
                            <div className="space-5"></div>
                            <p>
                                We are constantly growing, learning, and improving and our partners are steadily
                                increasing. 200 projects is a sizable number.
                            </p>
                            <div className="space-20"></div>
                            <div className="ot-progress pb-3" data-percent="65" data-processed="true">
                                <div className="overflow">
                                    <span className="pname f-left">interior sketch</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar">
                                        <span className="ppercent">65%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="ot-progress pb-3" data-percent="85" data-processed="true">
                                <div className="overflow">
                                    <span className="pname f-left">3D Modeling</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar">
                                        <span className="ppercent">85%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="ot-progress pb-3" data-percent="55" data-processed="true">
                                <div className="overflow">
                                    <span className="pname f-left">2D Planning</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar">
                                        <span className="ppercent">55%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 align-self-center">
                        <img src="images/image2-home3.jpg" alt="" />
                    </div>
                    <div className="space-150"></div>
                </div>
            </div> */}
				{/* <div className="container-fluid px-0 px-xl-90">
					<div className="bg-dark-1 px-3 py-5 p-sm-5 p-md-80">
						<div className="row">
							<div className="col-lg-12">
								<div className="clients-slide owl-theme owl-carousel">
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client1.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client2.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client3.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client4.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client5.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client6.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client1.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client2.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client3.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client4.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client5.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="img-item">
                                    <figure>
                                        <img src="images/client6.svg" alt="" />
                                    </figure>
                                </div>
                            </div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="container-fluid mb-5 px-0 px-xl-90">
					<OwlCarousel
						className="project-slider-4item projects-grid style-2 no-gaps m-0 img-scale owl-theme owl-carousel"
						loop
						nav
						key="projectsCarousel">
						<div className="item project-items category-19 ">
							<div className="projects-box">
								<div className="projects-thumbnail">
									<Link to={projects[0].route}>
										<img src={projects[0].img} alt="" />
									</Link>
									<div className="overlay">
										<h5>{projects[0].pageTitle}</h5>
										<i className="ot-flaticon-add"></i>
									</div>
								</div>
								<div className="portfolio-info">
									<div className="portfolio-info-inner">
										<h5>
											<Link className="title-link" to={projects[0].route}>
												{projects[0].pageTitle}
											</Link>
										</h5>
										<p className="portfolio-cates">
											<Link to={projects[0].route}>
												{projects[0].description}
											</Link>
										</p>
									</div>
									<Link className="overlay" to={projects[0].route}>
										&nbsp;
									</Link>
								</div>
							</div>
						</div>
						<div className="item project-items category-14 ">
							<div className="projects-box">
								<div className="projects-thumbnail">
									<Link to={projects[1].route}>
										<img src={projects[1].img} alt="" />
									</Link>
									<div className="overlay">
										<h5>{projects[1].pageTitle}</h5>
										<i className="ot-flaticon-add"></i>
									</div>
								</div>
								<div className="portfolio-info">
									<div className="portfolio-info-inner">
										<h5>
											<Link className="title-link" to={projects[1].route}>
												{projects[1].pageTitle}
											</Link>
										</h5>
										<p className="portfolio-cates">
											<Link to={projects[1].route}>
												{projects[1].description}
											</Link>
										</p>
									</div>
									<Link className="overlay" to={projects[1].route}>
										&nbsp;
									</Link>
								</div>
							</div>
						</div>
						<div className="item project-items category-15 ">
							<div className="projects-box">
								<div className="projects-thumbnail">
									<Link to={projects[2].route}>
										<img src={projects[2].img} alt="" />
									</Link>
									<div className="overlay">
										<h5>{projects[2].pageTitle}</h5>
										<i className="ot-flaticon-add"></i>
									</div>
								</div>
								<div className="portfolio-info">
									<div className="portfolio-info-inner">
										<h5>
											<Link className="title-link" to={projects[2].route}>
												{projects[2].pageTitle}
											</Link>
										</h5>
										<p className="portfolio-cates">
											<Link to={projects[2].route}>
												{projects[2].description}
											</Link>
										</p>
									</div>
									<Link className="overlay" to={projects[2].route}>
										&nbsp;
									</Link>
								</div>
							</div>
						</div>
						<div className="item project-items category-20 ">
							<div className="projects-box">
								<div className="projects-thumbnail">
									<Link to={projects[3].route}>
										<img src={projects[3].img} alt="" />
									</Link>
									<div className="overlay">
										<h5>{projects[3].pageTitle}</h5>
										<i className="ot-flaticon-add"></i>
									</div>
								</div>
								<div className="portfolio-info">
									<div className="portfolio-info-inner">
										<h5>
											<Link className="title-link" to={projects[3].route}>
												{projects[3].pageTitle}
											</Link>
										</h5>
										<p className="portfolio-cates">
											<Link to={projects[3].route}>
												{projects[0].description}
											</Link>
										</p>
									</div>
									<Link className="overlay" to={projects[3].route}>
										&nbsp;
									</Link>
								</div>
							</div>
						</div>
					</OwlCarousel>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Projects;
