import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import gallery1Img from "images/gallery-small-1.jpg";
import gallery2Img from "images/gallery-small-2.jpg";
import gallery3Img from "images/gallery-small-3.jpg";
import gallery4Img from "images/gallery-small-4.jpg";

import $ from "jquery";

import { routes } from "routes.js";

import { hero, contacts } from "site-structure.js";

let pathname;

const Header = (props) => {
	const [sidebarOpened, setSidebarOpened] = useState(false);
	pathname = useLocation();

	useEffect(() => {
		document.title = props.title;
	}, [props.title]);

	if (props.transparent) {
		return (
			<React.Fragment>
				<header id="site-header" className="site-header header-transparent">
					<div className="octf-main-header is-fixed">
						<div className="octf-area-wrap">
							<div className="container-fluid octf-mainbar-container">
								<div className="octf-mainbar">
									<div className="octf-mainbar-row octf-row">
										<div className="octf-col logo-col no-padding">
											<div id="site-logo" className="site-logo"></div>
										</div>

										<Elements />

										<div className="octf-col cta-col text-right no-padding">
											<div className="octf-btn-cta">
												<div className="octf-sidepanel octf-cta-header">
													<div className="site-overlay panel-overlay"></div>
													<div
														id="panel-btn"
														className="panel-btn octf-cta-icons"
														onClick={() => setSidebarOpened(!sidebarOpened)}>
														<i className="ot-flaticon-menu"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<Mobile />

				<Sidebar opened={sidebarOpened} setSidebarOpened={setSidebarOpened} />
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<header id="site-header" className="site-header header-transparent">
					<div className="header-desktop">
						<div className="octf-main-header main-header-s3 main-header-slight is-fixed">
							<div className="octf-area-wrap">
								<div className="container-fluid octf-mainbar-container">
									<div className="octf-mainbar">
										<div className="octf-mainbar-row octf-row">
											<div className="octf-col logo-col no-padding">
												<div id="site-logo" className="site-logo">
													<div className="logo-h3" />
												</div>
											</div>

											<Elements activeElement={props.activeElement} />

											<div className="octf-col cta-col text-right no-padding">
												<div className="octf-btn-cta">
													<div className="octf-sidepanel octf-cta-header">
														<div className="site-overlay panel-overlay"></div>
														<div
															id="panel-btn"
															className="panel-btn octf-cta-icons"
															onClick={() => setSidebarOpened(!sidebarOpened)}>
															<i className="ot-flaticon-menu"></i>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<Mobile />

				<Sidebar opened={sidebarOpened} setSidebarOpened={setSidebarOpened} />
			</React.Fragment>
		);
	}
};

const Mobile = () => {
	return (
		<>
			<div className="header_mobile header-mobile-s3">
				<div className="container-fluid">
					<div className="octf-mainbar-row octf-row">
						<div className="octf-col">
							<div className="mlogo_wrapper clearfix">
								<div
									className="mobile_logo"
									style={{
										height: "100%",
										color: "#fff",
										display: "flex",
										alignItems: "center",
										fontSize: 32,
										paddingLeft: 15,
										lineHeight: "34px",
									}}>
									<a href="index.html"></a>
								</div>
							</div>
						</div>
						<div
							className="octf-col justify-content-end"
							style={{ height: 72 }}>
							<div className="octf-menu-mobile octf-cta-header">
								<div
									id="mmenu-toggle"
									className="mmenu-toggle"
									onClick={() => {
										document.getElementById("mmenu-wrapper").style.transform =
											"translateX(0%)";
									}}>
									<button>
										<i className="ot-flaticon-menu"></i>
									</button>
								</div>
								<div className="site-overlay mmenu-overlay"></div>
								<div id="mmenu-wrapper" className="mmenu-wrapper on-right">
									<div className="mmenu-inner">
										<a
											className="mmenu-close"
											href="/"
											onClick={(e) => {
												e.preventDefault();
												document.getElementById(
													"mmenu-wrapper"
												).style.transform = "translateX(100%)";
											}}>
											<i className="ot-flaticon-right-arrow"></i>
										</a>
										<div className="mobile-nav">
											<ul
												id="menu-main-menu"
												className="mobile_mainmenu none-style">
												{getRoutes(routes)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const Elements = (props) => {
	return (
		<React.Fragment>
			<div className="octf-col menu-col no-padding">
				<nav id="site-navigation" className="main-navigation nav-text-dark">
					<ul className="menu">{getRoutes(routes)}</ul>
				</nav>
			</div>
		</React.Fragment>
	);
};

const Sidebar = ({ opened, setSidebarOpened }) => {
	$("body").toggleClass("side-panel-active", opened);

	$(".site-overlay.panel-overlay").on("click", () => {
		setSidebarOpened(!opened);
	});

	return (
		<React.Fragment>
			<div
				id="side-panel"
				className={opened ? "side-panel side-panel-open" : "side-panel"}>
				<a
					className="side-panel-close"
					onClick={() => {
						setSidebarOpened(!opened);
					}}>
					<i className="ot-flaticon-close-1"></i>
				</a>
				<div className="side-panel-block">
					<div className="side-panel-wrap">
						<div className="ot-heading">
							<h2 className="main-heading">Galleria progetti</h2>
						</div>
						<div className="image-gallery">
							<div
								id="gallery-1"
								className="gallery galleryid-102 gallery-columns-3 gallery-size-thumbnail">
								<figure className="gallery-item">
									<div className="gallery-icon landscape">
										<a
											data-elementor-open-lightbox="yes"
											data-elementor-lightbox-slideshow="8701e24"
											data-elementor-lightbox-title="p4-gallery1">
											<img src={gallery1Img} className="" alt="" />
										</a>
									</div>
								</figure>
								<figure className="gallery-item">
									<div className="gallery-icon landscape">
										<a
											data-elementor-open-lightbox="yes"
											data-elementor-lightbox-slideshow="8701e24"
											data-elementor-lightbox-title="p4-gallery1">
											<img src={gallery2Img} className="" alt="" />
										</a>
									</div>
								</figure>
								<figure className="gallery-item">
									<div className="gallery-icon landscape">
										<a
											data-elementor-open-lightbox="yes"
											data-elementor-lightbox-slideshow="8701e24"
											data-elementor-lightbox-title="p4-gallery1">
											<img src={gallery3Img} className="" alt="" />
										</a>
									</div>
								</figure>
								<figure className="gallery-item">
									<div className="gallery-icon landscape">
										<a
											data-elementor-open-lightbox="yes"
											data-elementor-lightbox-slideshow="8701e24"
											data-elementor-lightbox-title="p4-gallery1">
											<img src={gallery4Img} className="" alt="" />
										</a>
									</div>
								</figure>
							</div>
						</div>
						<div className="ot-heading ">
							<h2 className="main-heading">Contatti</h2>
						</div>
						<div className="side-panel-cinfo">
							<ul className="panel-cinfo">
								<li className="panel-list-item">
									<span className="panel-list-icon">
										<i className="ot-flaticon-place"></i>
									</span>
									<span className="panel-list-text">
										{contacts.shortAddress}
									</span>
								</li>
								<li className="panel-list-item">
									<span className="panel-list-icon">
										<i className="ot-flaticon-mail"></i>
									</span>
									<span className="panel-list-text">{contacts.mail}</span>
								</li>
								<li className="panel-list-item">
									<span className="panel-list-icon">
										<i className="ot-flaticon-phone-call"></i>
									</span>
									<span className="panel-list-text">{contacts.phone[0]}</span>
								</li>
								<li className="panel-list-item">
									<span className="panel-list-icon">
										<i className="ot-flaticon-phone-call"></i>
									</span>
									<span className="panel-list-text">{contacts.phone[1]}</span>
								</li>
							</ul>
						</div>
						<div className="side-panel-social">
							<ul>
								<li>
									<Link key="facebook" to={contacts.facebook} target="_blank">
										<i className="fab fa-facebook-f"></i>
									</Link>
								</li>
								<li>
									<Link key="instagram" to={contacts.instagram} target="_blank">
										<i className="fab fa-instagram"></i>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const getRoutes = (routes) =>
	routes.map((route) => {
		if ("route" in route) {
			// se è una route con figli
			if ("collapse" in route) {
				if (route.collapseType === "standard") {
					return (
						<li
							key={route.key}
							className={
								pathname.pathname.indexOf(route.route) > -1
									? "current-menu-item current-menu-ancestor menu-item-has-children"
									: "menu-item-has-children"
							}>
							<a>{route.section}</a>
							<ul className="sub-menu">{getRoutes(route.collapse)}</ul>
						</li>
					);
				} else if (route.collapseType === "megamenu") {
					return (
						<li
							key={route.key}
							className={
								pathname.pathname.indexOf(route.route) > -1
									? "current-menu-item current-menu-ancestor menu-item-has-children mega-dropdown"
									: "menu-item-has-children mega-dropdown"
							}>
							<a>{route.section}</a>
							<ul className="mega-sub-menu">
								<li className="row">
									{route.collapse.map((route) => {
										return (
											<ul className="col">
												<li className="menu-title">{route.section}</li>
												{getRoutes(route.collapse)}
											</ul>
										);
									})}
								</li>
							</ul>
						</li>
					);
				}
			} else {
				// se invece è una route senza figli
				// stampo il link se va
				if ("linkName" in route) {
					return (
						<li
							key={route.key}
							className={
								pathname.pathname.indexOf(route.route) > -1
									? "current-menu-item"
									: ""
							}>
							<Link to={route.route} key={route.key + "-link"}>
								{route.linkName}
							</Link>
						</li>
					);
				}
			}
		}

		return null;
	});

export default Header;
