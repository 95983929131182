import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { routes } from "routes.js";

const App = () => {
	const { pathname } = useLocation();

	//setto scroll pagina a 0 quando viene cambiata route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (routes) =>
		routes.map((route) => {
			if ("collapse" in route) {
				return getRoutes(route.collapse);
			}

			return (
				<Route
					exact
					path={route.route}
					element={route.component}
					key={route.key}></Route>
			);
		});

	return (
		<>
			<Routes>
				<Route path="*" element={<Navigate to="/home" />} />

				{getRoutes(routes)}
			</Routes>
		</>
	);
};

export default App;
