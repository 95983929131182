import React from "react";

import pcImg from "images/image1-single-team.jpg";
import bgImg from "images/bg1-single-team.jpg";
import sectionImg from "images/bg1-home3.png";

const Sponsor = () => {
	return (
		<React.Fragment>
			<div
				className="single-team"
				style={{
					backgroundImage: `url(${sectionImg})`,
					backgroundRepeat: "no-repeat",
				}}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-12 text-center align-self-center mb-5 mb-lg-0">
							<div className="team-member-img">
								<img src={pcImg} alt="" />
							</div>
						</div>
						<div className="col-lg-8 col-md-12">
							<div
								className="team-member-info"
								style={{ backgroundImage: `url(${bgImg})` }}>
								<h2>Sponsor ufficiale</h2>
								<h5> [ Piacenza calcio ]</h5>
								<ul className="member-info">
									<li>
										Gagliardi costruzioni è sponsor del piacenza calcio dal{" "}
										<span className="text-light">2020</span>
									</li>
									<li>
										<span className="text-light">DETTAGLIO:</span> Come e perchè
										questa collaborazione
									</li>
								</ul>
								<div className="list-social">
									<ul>
										<li>
											<a
												href="https://www.facebook.com/PiacenzaCalcio/?locale=it_IT"
												target="_self">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a
												href="https://www.instagram.com/piacenzacalcio1919/"
												target="_self">
												<i className="fab fa-instagram"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Sponsor;
