import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Header from "components/Header";
import Footer from "components/Footer";

import contactBgImg from "images/contatti-min.jpg";
import contactCardBgImg from "images/bg1-contact.jpg";

import { contacts } from "site-structure.js";

const Contatti = (props) => {
	const page = props.page;

	const ref = React.createRef();

	const onSubmit = (mailMessage) => {
		window.location.href = `mailTo:${contacts.mail}?subject=Contatto da sito a nome ${mailMessage.sender}&body=${mailMessage.text}`;
	};

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			sender: "",
			text: "",
		},
	});

	return (
		<React.Fragment>
			<div id="page" className="site">
				<Header transparent={true} title={props.title} />

				<div id="content" className="site-content">
					<div
						className="page-header dtable text-center header-transparent page-header-contact"
						style={{
							backgroundImage: `linear-gradient(0deg, rgba(33, 33, 33, .42) 0%, rgba(33, 33, 33, .42) 100%), url(${contactBgImg})`,
						}}>
						<div className="dcell">
							<div className="container">
								<h1 className="page-title">{page.pageTitle}</h1>
								<ul id="breadcrumbs" className="breadcrumbs none-style">
									<li>
										<a href="index.html">Home</a>
									</li>
									<li className="active">{page.linkName}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<section className="contact-page">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 align-self-center mb-5 mb-lg-0">
								<div className="contact-left">
									<h2>Contattaci</h2>
									<p className="font14">
										Il tuo indirizzo e-mail non verrà pubblicato.
									</p>
									<form
										id="ajax-form"
										name="ajax-form"
										action="contact.php"
										method="post"
										className="wpcf7">
										<div className="main-form">
											<p>
												<Controller
													key="sender"
													name="sender"
													control={control}
													rules={{
														required: "Il nome è obbligatorio",
														minLength: {
															value: 3,
															message:
																"Il nome deve essere lungo almeno 3 caratteri",
														},
													}}
													render={({ field }) => (
														<input
															ref={ref}
															{...field}
															type="text"
															size="40"
															className=""
															placeholder="Scrivi qui il tuo nome *"
														/>
													)}
												/>
												<ErrorMessage
													key="senderError"
													errors={errors}
													name="sender"
													render={({ message }) => <div>{message}</div>}
												/>
											</p>
											<p>
												<Controller
													key="text"
													name="text"
													control={control}
													rules={{
														required: "Il testo è obbligatorio",
														minLength: {
															value: 3,
															message:
																"Il testo deve essere lungo almeno 3 caratteri",
														},
													}}
													render={({ field }) => (
														<textarea
															ref={ref}
															{...field}
															cols="40"
															rows="10"
															className=""
															placeholder="Scrivi qui il testo del messaggio *"></textarea>
													)}
												/>
												<ErrorMessage
													key="textError"
													errors={errors}
													name="text"
													render={({ message }) => <div>{message}</div>}
												/>
											</p>
											<p>
												<button
													ref={ref}
													className="octf-btn"
													onClick={handleSubmit(onSubmit)}>
													Invia
												</button>
											</p>
											<div className="clear"></div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-6">
								<div
									className="contact-right"
									style={{ backgroundImage: `url(${contactCardBgImg})` }}>
									<div className="ot-heading">
										<span>[ Contatti ]</span>
										<h2 className="main-heading">Richiedi un preventivo</h2>
									</div>
									<p>
										Chiamaci o scrivici quando desideri, rispondiamo sempre nel
										minor tempo possibile. Saremo felici di rispondere a tutte
										le vostre domande.
									</p>
									<div className="contact-info">
										<i className="ot-flaticon-place"></i>
										<div className="info-text">
											<h6>INDIRIZZO:</h6>
											<p>{contacts.address}</p>
										</div>
									</div>
									<div className="contact-info">
										<i className="ot-flaticon-mail"></i>
										<div className="info-text">
											<h6>MAIL:</h6>
											<p>
												<Link to={`mailTo:${contacts.mail})`}>
													{contacts.mail}
												</Link>
											</p>
										</div>
									</div>
									<div className="contact-info">
										<i className="ot-flaticon-phone-call"></i>
										<div className="info-text">
											<h6>TELEFONO:</h6>
											<p>
												<Link to={`tel:${contacts.phone[0]})`}>
													{contacts.phone[0]}
												</Link>
											</p>
											<p>
												<Link to={`tel:${contacts.phone[1]})`}>
													{contacts.phone[1]}
												</Link>
											</p>
										</div>
									</div>
									<div className="list-social">
										<ul>
											<li>
												<Link to={contacts.facebook} target="_blank">
													<i className="fab fa-facebook-f"></i>
												</Link>
											</li>
											<li>
												<Link to={contacts.instagram} target="_blank">
													<i className="fab fa-instagram"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="contact-map">
					<div className="map">
						<iframe
							width="1920"
							height="522"
							frameBorder="0"
							scrolling="no"
							marginHeight="0"
							marginWidth="0"
							id="gmap_canvas"
							src="https://maps.google.com/maps?width=1920&amp;height=522&amp;hl=en&amp;q=Via%20Armando%20Diaz%2045%20Codogno+(Gagliardi%20costruzioni)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
					</div>
				</div>

				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Contatti;
