import React, { useEffect } from "react";

import Header from "components/Header";
import Hero from "components/Hero";
import Philosophy from "components/Philosophy";
import Projects from "components/Projects";
import Services from "components/Services";
import Counters from "components/Counters";
import Sponsor from "components/Sponsor";
import Testimonial from "components/Testimonial";
import Footer from "components/Footer";

const Home = (props) => {
	return (
		<React.Fragment>
			<div id="page" className="site">
				<Header transparent={true} title={props.title} />

				<div id="content" className="site-content">
					<div>
						<Hero />

						<Philosophy />

						<Services />

						<Counters />

						<Projects />

						<Testimonial />

						<Sponsor />
					</div>
				</div>

				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Home;
