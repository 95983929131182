const testimonial = [
	{
		key: "1",
		name: "Anna P.",
		text: "Marco e tutto lo staff mi hanno ristrutturato una vecchia casa di cortile: fin dalle prime fasi mi hanno supportato con consigli e indicazioni preziose. Sono sempre stati precisi, puliti e molto gentili con i miei anziani genitori a volte un pochino....'pesanti'. Anche nei miei momenti di crisi/panico, Marco è sempre stato calmo e presente. La casa ora è completamente rinnovata in modo efficiente e moderno ed è 'la mia casa'. grazie a Marco e tutto lo staff!",
	},
	{
		key: "2",
		name: "Silvio S.",
		text: "Precisi, puliti, competenti, cordiali che non è poco. Il titolare è molto presente per supportare i suoi dipendenti e verificare i lavori. Andate avanti così.",
	},
	{
		key: "3",
		name: "Sara M.",
		text: "Professionali, precisi e onesti. Mi hanno rifatto il portico, lavoro stupendo davvero soddisfatta. Lo consiglio.",
	},
	{
		key: "4",
		name: "Marika S.",
		text: "Abbiamo deciso di affidare a Marco e alla sua impresa il rifacimento della facciata e l'installazione del cappotto termico della nostra villetta. I lavori sn stati effettuati con estrema precisione, curando ogni dettaglio, e nei tempi prestabiliti. Il personale è gentile, professionale, competente e lavora sempre in sicurezza. Ci siamo trovati benissimo e siamo molto soddisfatti del risultato finale!",
	},
	{
		key: "5",
		name: "Angela A.",
		text: "Grazie mille a Marco e tutta la sua squadra che con la loro serietà e professionalità mi hanno permesso di realizzare la casa che ho sempre desiderato. Per non parlare della velocità e della precisione con cui hanno realizzato i lavori di ristrutturazione, non avrei potuto affidarmi ad impresa migliore.",
	},
];

export default testimonial;
