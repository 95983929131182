import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import sliderImg from "images/apertura_home_page-min.jpeg";
import bannerImg from "images/bg/bg1-home4.jpg";

import componentImg from "images/slider/bg-slider-home3.png";

import logoImg from "images/logo.jpg";
import heroImg from "images/slider/slider2-home3.jpg";

import { hero, contacts } from "site-structure.js";

const Hero = () => {
	return (
		<React.Fragment>
			{/* <section
				className="hero"
				style={{ backgroundImage: `url(${componentImg})` }}>
				<div className="grid-lines grid-lines-vertical">
					<span className="g-line-vertical line-left color-line-default"></span>
					<span className="g-line-vertical line-right color-line-default"></span>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 align-self-center">
							<div className="col-lg-12 justify-content-center">
								<div
									className="logo"
									style={{ backgroundImage: `url(${logoImg})` }}></div>
								<div className="title mb-2">
									Gagliardi
									<br />
									costruzioni
								</div>
								<div className="subtitle mb-4">di Gagliardi Marco</div>
								<div className="description">{hero.subtitle}</div>
								<Link
									to={contacts.route}
									className="octf-btn octf-btn-primary btn-slider btn-large border-hover-dark">
									Contattaci
								</Link>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 mt-5 mb-lg-0 align-self-center">
							<div
								className="hero-img"
								style={{ backgroundImage: `url(${heroImg})` }}></div>
						</div>
					</div>
				</div>
			</section> */}
			<section
				className="banner-4"
				style={{
					backgroundImage: `linear-gradient(0deg, rgba(33, 33, 33, .42) 0%, rgba(33, 33, 33, .42) 100%), url(${sliderImg})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center 45%",
				}}>
				<div className="container">
					<div className="row">
						<div
							className="col-xl-7 col-lg-12"
							style={{ position: "relative", top: "40px" }}>
							<h2 style={{ color: "#fff" }}>
								Gagliardi <br /> Costruzioni
							</h2>
							<div className="s-dark flex-middle justify-content-start">
								<span style={{ color: "#fff" }}>{hero.subtitle}</span>
							</div>
							<div className="banner-desc-4">
								<p>{hero.subtitle}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				className="feature-4"
				style={{
					backgroundImage: `url(${bannerImg})`,
				}}>
				<div className="container">
					<div className="feature-wrap">
						<div className="feature-item mb-4 mb-lg-0">
							<h6 className="number">01.</h6>
							<p>Sopralluogo sul posto</p>
						</div>
						<div className="feature-item mb-4 mb-lg-0">
							<h6 className="number">02.</h6>
							<p>Studio della soluzione</p>
						</div>
						<div className="feature-item">
							<h6 className="number">03.</h6>
							<p>Realizzazione progetto</p>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Hero;
