import React from "react";

import "./index.scss";

import philosophyImg from "images/image1-home3.png";

import { about } from "site-structure.js";

const About = () => {
	return (
		<React.Fragment>
			<section className="about-3 p-xl-0 pb-sm-0">
				<div className="grid-lines grid-lines-vertical">
					<span className="g-line-vertical line-left color-line-default"></span>
					<span className="g-line-vertical line-right color-line-default"></span>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 mb-5 mb-lg-0 align-self-center">
							<div className="about-img-3">
								<img src={philosophyImg} alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 align-self-center">
							<div className="about-content-3 ml-xl-70">
								<div className="ot-heading is-dots">
									<span>[ {about.sectionSubtitle} ]</span>
									<h2 className="main-heading">{about.sectionTitle}</h2>
								</div>
								<div className="space-20"></div>
								<div className="space-5"></div>
								<p>{about.sectionDescription}</p>
								<div className="space-20"></div>
								<div className="row">
									<div className="col-md-6 col-sm-12 mb-5 mb-md-0">
										<div className="icon-box icon-box--classNameic icon-box--icon-top pr-4">
											<div className="icon-main">
												<span className="ot-flaticon-3d"></span>
											</div>
											<div className="content-box">
												<h5>
													<a>{about.sectionPillars[0].title}</a>
												</h5>
												<p>{about.sectionPillars[0].description}</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										<div className="icon-box icon-box--classNameic icon-box--icon-top">
											<div className="icon-main">
												<span className="ot-flaticon-blueprint"></span>
											</div>
											<div className="content-box">
												<h5>
													<a href="about-us.html">
														{about.sectionPillars[1].title}
													</a>
												</h5>
												<p>{about.sectionPillars[1].description}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default About;
