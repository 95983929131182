import service1Img from "images/services/costruzioni-min.jpg";
import service2Img from "images/services/ristrutturazioni-min.jpg";
import service3Img from "images/services/coperture-min.jpg";
import service4Img from "images/services/manutenzioni-min.jpg";
import service5Img from "images/services/movimento-terra-min.jpg";
import service6Img from "images/services/isolamenti-termici-min.jpg";
import service7Img from "images/services/rifacimento-bagno-min.jpg";

import project1Img from "images/projects-grid/project1.jpg";
import project2Img from "images/projects-grid/project2.jpg";
import project3Img from "images/projects-grid/project3.jpg";
import project4Img from "images/projects-grid/project4.jpg";

const hero = {
	title: "Gagliardi costruzioni",
	subtitle: "Costruiamo il tuo futuro mattone su mattone.",
};

const home = {
	pageTitle: "Home",
	description: hero.subtitle,
	section: "Home",
	linkName: "Home",
	route: "/home",
};

const about = {
	pageTitle: "Chi siamo",
	description: "",
	section: "Chi siamo",
	linkName: "Chi siamo",
	route: "/chi-siamo",
	sectionTitle: "La nostra impresa",
	sectionSubtitle: "Panoramica",
	sectionDescription:
		"Il nostro obiettivo è la realizzazione di opere di alta qualità, sia dal punto di vista estetico che strutturale. Solo così possiamo garantire costruzioni solide e allo stesso tempo eleganti e funzionali.",
	sectionPillars: [
		{
			title: "Qualità",
			description:
				"Garantiamo qualità estetica e funzionale per soddisfare al meglio le esigenze dei clienti.",
		},
		{
			title: "Innovazione",
			description:
				"Utilizziamo le tecniche più avanzate e i materiali migliori per garantire efficenza e durabilità.",
		},
	],
};

const services = [
	{
		pageTitle: "Costruzioni",
		description: "Costruzioni.",
		section: "Servizi",
		linkName: "Costruzioni",
		text: "",
		img: service1Img,
		route: "/servizi/costruzioni",
	},
	{
		pageTitle: "Ristrutturazioni",
		description: "Ristrutturazioni.",
		section: "Servizi",
		linkName: "Ristrutturazioni",
		text: "",
		img: service2Img,
		route: "/servizi/ristrutturazioni",
	},
	{
		pageTitle: "Coperture",
		description: "Coperture.",
		section: "Servizi",
		linkName: "Coperture",
		text: "",
		img: service3Img,
		route: "/servizi/coperture",
	},
	{
		pageTitle: "Manutenzioni",
		description: "Manutenzioni.",
		section: "Servizi",
		linkName: "Manutenzioni",
		text: "",
		img: service4Img,
		route: "/servizi/manutenzioni",
	},
	{
		pageTitle: "Movimento terra",
		description: "Movimento terra.",
		section: "Servizi",
		linkName: "Movimento terra",
		text: "",
		img: service5Img,
		route: "/servizi/movimento-terra",
	},
	{
		pageTitle: "Isolamenti termici",
		description: "Isolamenti termici.",
		section: "Servizi",
		linkName: "Isolamenti termici",
		text: "",
		img: service6Img,
		route: "/servizi/isolamenti-termici",
	},
	{
		pageTitle: "Rifacimento bagno",
		description: "Rifacimento bagno.",
		section: "Servizi",
		linkName: "Rifacimento bagno",
		text: "",
		img: service7Img,
		route: "/servizi/rifacimento-bagno",
	},
];

const projects = [
	{
		pageTitle: "Codogno",
		description: "Ristrutturazione",
		section: "Realizzazioni",
		linkName: "Codogno",
		img: project1Img,
		route: "/realizzazioni/codogno",
	},
	{
		pageTitle: "Piacenza",
		description: "Ristrutturazione",
		section: "Realizzazioni",
		linkName: "Piacenza",
		img: project2Img,
		route: "/realizzazioni/piacenza",
	},
	{
		pageTitle: "Lodi Vecchio",
		description: "Ristrutturazione",
		section: "Realizzazioni",
		linkName: "Lodi Vecchio",
		img: project3Img,
		route: "/realizzazioni/lodi-vecchio",
	},
	{
		pageTitle: "Maleo",
		description: "Ristrutturazione",
		section: "Realizzazioni",
		linkName: "Maleo",
		img: project4Img,
		route: "/realizzazioni/maleo",
	},
];

const projectsPage = {
	pageTitle: "Realizzazioni",
	section: "Realizzazioni",
	linkName: "Realizzazioni",
	pageTitle: "Realizzazioni",
};

const contacts = {
	pageTitle: "Contatti",
	description: "",
	section: "Contatti",
	linkName: "Contatti",
	route: "/contatti",
	address: "Via Armando Diaz, 26845, Codogno (LO)",
	shortAddress: "Via Armando Diaz, 26845",
	mail: "marco.gagliardicostruzioni@gmail.com",
	phone: ["338 784 5683", "366 712 8001"],
	instagram: "https://www.instagram.com/gagliardicostruzioni/",
	facebook: "https://www.facebook.com/gagliacostruzioni/?locale=it_IT",
};

const counters = [
	{
		name: "Clienti soddisfatti",
		value: 30,
	},
	{
		name: "Anni di esperienza",
		value: 10,
	},
];

export {
	hero,
	home,
	about,
	services,
	projects,
	projectsPage,
	contacts,
	counters,
};
