import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import logoImg from "images/logo.jpg";
import bgImg from "images/bg2-home3.jpg";

import testimonials from "testimonial.js";

const Testimonial = () => {
	const options = {
		items: 1,
		nav: true,
		navText: [
			"<i class='ot-flaticon-left-arrow'></i>",
			"<i class='ot-flaticon-right-arrow'></i>",
		],
		rewind: false,
		autoplay: false,
		slideBy: 1,
		dots: false,
		dotsEach: true,
		dotData: true,
		// responsive: {
		// 	1000: {
		// 		items: 1,
		// 	},
		// 	767: {
		// 		items: 1,
		// 	},
		// 	0: {
		// 		items: 1,
		// 	},
		// },
	};

	const renderTestimonial = () =>
		testimonials.map((testimonial) => (
			<div key={testimonial.name.replace(" ", "")}>
				<div className="ttext">{testimonial.text}</div>
				<div className="t-head flex-middle">
					<div className="tinfo">
						<h5>{testimonial.name}</h5>
					</div>
				</div>
			</div>
		));

	return (
		<React.Fragment>
			<section className="testi-3 py-0">
				<div className="grid-lines grid-lines-vertical">
					<span className="g-line-vertical line-left color-line-default"></span>
					<span className="g-line-vertical line-right color-line-default"></span>
				</div>
				<div className="container-fluid px-xl-90">
					<div className="row mx-0">
						<div className="col-lg-6 px-0 align-self-center">
							<div className="testi-img-3">
								<img src={logoImg} alt="" />
							</div>
						</div>
						<div className="col-lg-6 px-0 mb-5 mb-xl-0">
							<div
								className="testi-slide-block-3"
								style={{ backgroundImage: `url(${bgImg})` }}>
								<div className="ot-heading is-dots">
									<span>[ Testimonianze ]</span>
									<h2 className="main-heading">Cosa dicono di noi</h2>
								</div>
								<div className="space-20"></div>
								<div className="space-5"></div>
								<div className="ot-testimonials v-light">
									<OwlCarousel
										key="testimonialCarousel"
										className="testimonials-slide-2 s2 ot-testimonials-slider-s2 owl-theme owl-carousel"
										{...options}>
										{renderTestimonial()}
									</OwlCarousel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Testimonial;
